<template>
    <div class="NoticeList">
        <div class="main">
            <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
                <el-tab-pane label="资讯中心" name="3">
                    <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
                        <el-breadcrumb-item :to="{ path: '/zixun' }">资讯中心</el-breadcrumb-item>
                        <el-breadcrumb-item>实时公告</el-breadcrumb-item>
                    </el-breadcrumb>
                    <el-card class="box-card" v-if="modes =='Bidding'">
                        <div class="main-title">承诺函</div>
                        <div class="main-body">
                            <div class="body-table">
                                <div class="table-list">
                                    <div class="card-message mt-3 mb-3" v-for="(item,index) in dataBiddinglist" :key="index">
                                        <div style="float:left; border:1px solid #8c939d;height: 60px;text-align: center;margin-right: 20px;width: 100px" >
                                            <img v-if="item.thumb != ''&& item.thumb != null" :src="myPic(item.thumb)" class="card_imgsize"/>
                                            <img v-else :src="require('../assets/imgs/cart-empty.png')" class="card_imgsize"/>
                                        </div>
                                        <div @click="gotoDetails(item.targetname,'public',item.targetid)" class="card-message_t card_size cursors">标的物名称：{{item.targetname}}</div>
                                        <div class="card-message_b card_size">挂牌价：{{item.price}} 、加价幅度：{{ item.minmarkup}}、服务费：{{ item.servicecharge}}、开始时间：{{item.begintime}}、结束时间{{ item.endtime}}</div>
                                        <div style="overflow: hidden;font-size: 14px;margin-top: 4px">
        <!--                                    <a>......[详细]</a>-->
                                            <div style="float: right;color: #c0bdbd">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--    分页-->
                                <div class="block" align="right">
                                    <el-pagination
                                            @size-change="bidsizeChangeHandle"
                                            @current-change="bidcurrentChangeHandle"
                                            :current-page="bidpageIndex"
                                            :page-sizes="[10, 20, 50, 100]"
                                            :page-size="bidpageSize"
                                            :total="bidtotalPage"
                                            layout="total, sizes, prev, pager, next, jumper">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    export default {
        created() {
            this.activeName = '3';
        },
        data() {
            return {
                //公共样式
                qualityTableStyle: {
                    'background': 'whitesmoke',
                    'font-weight': 'bold',
                    'font-size': ' 12px',
                    'font-family': "Microsoft YaHei",
                    'color': '#333333',
                    'font-weight': '400',
                    'text-align': 'center',
                },
                //竞买 或者竞卖
                modes:'',
                // NoticeList: [],
                bidnames: '',//竞价场次名称
                //////////////////////////////////////////////////////////////
                timeDate: '',//开始结束时间
                dataBiddinglist: [],
                pageIndex: 1,
                pageSize: 10,
                bidpageIndex: 1,
                bidpageSize: 10,
                totalPage: 0,
                bidtotalPage: 0,
                activeName: '3',
                //分页数据
                dataForm: {
                    productid: '',
                    delivery: '',
                    outbidstate: '',
                    splitstate: ''
                },
                ////////////////////////////////////////////////////
                // dataListLoading: false,
                //竞买公告列表
                dataProcurelist:[],
                //煤种
                coal: '',
                coalList: [],
                //采购单位
                purchasingUnit: '',
                purchasingUnitList: [],
            }
        },
        activated(){

            this.modes=this.$route.query.modes;
        },
        mounted() {
            // this.getBiddingDataList();
            this.getBiddingDataList()
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
               // 获取 竞拍公告数据列表
            getBiddingDataList() {
            this.$fetch('/shoppingui/bidShopping/targetPageList', {
                'page': this.pageIndex,
                'limit': this.pageSize,
                'order': 'asc',
                // 'delivery': this.dataForm.delivery,
                // 'outbidstate': this.dataForm.outbidstate,
                // 'splitstate': this.dataForm.splitstate,
                // 'begintime': this.timeDate === ''|| this.timeDate === null ? '' :this.formateDate(this.timeDate[0]) +' ' + '00:00:00',
                // 'endtime': this.timeDate === '' || this.timeDate === null ? '':this.formateDate(this.timeDate[1]) + ' ' + '23:59:59',
                // 'bidname': this.bidnames === '' || this.bidnames === null? '':this.bidnames,
            }, true).then((data) => {
                if (data && data.code === 0) {
                     this.dataBiddinglist = data.data.list;
                        this.bidtotalPage = data.data.total
                } else {
                this.dataBiddinglist = []
                this.bidtotalPage = 0
                this.$message({
                    message: data.msg + '获取失败',
                    type: 'error',
                    showClose: 'true'
                })
                }
            })
            },
            // 竞卖每页数
            bidsizeChangeHandle(val) {
                this.bidpageSize = val
                this.bidpageIndex = 1
                //调用查询信息接口
                this.getBiddingDataList();
            },
            // 竞卖当前页
            bidcurrentChangeHandle(val) {
                this.bidpageIndex = val
                //调用查询信息接口
                this.getBiddingDataList();
            },
            //竞卖详情
            gotoDetails(name,title,targetid) {
                let queryData = {};
                queryData.bidname = name;
                queryData.title = title;
                queryData.targetid = targetid;
                // queryData.modes = modes;
                // this.$router.push({path: "/zxXiangQing", query: queryData});
            },
            //竞买详情
            gotNoticeProduct(name,title,ptargetid) {
                let queryData = {};
                queryData.bidname = name;
                queryData.title = title;
                queryData.ptargetid = ptargetid;
                // queryData.modes = mode;
                this.$router.push({path: "/ProductXQ", query: queryData});
            },
               myPic(e){
                if(e.length >0){
                return this.$ImgUrl+e[0]
                }
                },

        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/css/index.css";
    @import "../assets/css/page/zixun.scss";
    @import "../assets/css/page/NoticeList.scss";
    /* 更改element-UI按钮样式 */
    .el-button--text {
        color: #b0b0b0;
    }

    .el-button--text:hover {
        color: #b0b0b0;
        font-weight: bold;
    }

    .el-button--text:focus {
        color: #000000;
        font-weight: bold;
    }

</style>
